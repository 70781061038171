import React, { useState, useEffect } from 'react';
import { 
  Tabs, 
  Tab, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Paper,
  Box,
  Typography,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

const WellSummary = ({ well }) => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [inventorySummary, setInventorySummary] = useState({
    onTurnkey: {},
    offTurnkey: {},
    combined: {}
  });
  const [serviceSummary, setServiceSummary] = useState({
    onTurnkey: {},
    offTurnkey: {},
    combined: {}
  });
  const [miscCharges, setMiscCharges] = useState({
    onTurnkey: [],
    offTurnkey: [],
    combined: []
  });
  const [inventoryItems, setInventoryItems] = useState({});
  const [serviceItems, setServiceItems] = useState({});
  const [dwrTotals, setDwrTotals] = useState([]);

  // First fetch all inventory items to get their names
  useEffect(() => {
    const fetchInventoryItems = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.get(
          `${process.env.REACT_APP_APP_URL}/jmrapp/inventoryitems/`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        
        // Create a map of id to name
        const itemMap = response.data.reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});
        
        setInventoryItems(itemMap);
      } catch (error) {
        console.error('Error fetching inventory items:', error);
      }
    };

    fetchInventoryItems();
  }, []);

  useEffect(() => {
    const fetchServiceItems = async () => {
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.get(
          `${process.env.REACT_APP_APP_URL}/jmrapp/serviceitems/`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        
        const itemMap = response.data.reduce((acc, item) => {
          acc[item.id] = item.name;
          return acc;
        }, {});
        
        setServiceItems(itemMap);
      } catch (error) {
        console.error('Error fetching service items:', error);
      }
    };
  
    fetchServiceItems();
  }, []);

  // Fetch DWR details for the well
  useEffect(() => {
    const fetchDWRData = async () => {
      if (!well?.id) return;
      
      try {
        const token = localStorage.getItem('my-jwt');
        const response = await axios.get(
          `${process.env.REACT_APP_APP_URL}/jmrapp/dailyworkrecords/?well=${well.id}&detail=true`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        // console.log('DWR Data:', response.data);

        // Calculate off-turnkey totals for each DWR
        const dwrWithTotals = response.data.map(dwr => ({
            id: dwr.id,
            date: dwr.date,
            offTurnkeyTotal: dwr.charge_records
                .filter(record => !record.on_turnkey)
                .reduce((total, record) => total + (Number(record.total_amount) || 0), 0),
                time_record: dwr.time_record
            }));
            setDwrTotals(dwrWithTotals);
          

        const summary = {
          inventory: { onTurnkey: {}, offTurnkey: {}, combined: {} },
          service: { onTurnkey: {}, offTurnkey: {}, combined: {} },
          misc: { onTurnkey: [], offTurnkey: [], combined: [] }
        };

        response.data.forEach(dwr => {
          if (!dwr.charge_records) return;

          dwr.charge_records.forEach(record => {
            const type = record.on_turnkey ? 'onTurnkey' : 'offTurnkey';

            // Process inventory items
            if (record.inventory_item_charges) {
              record.inventory_item_charges.forEach(charge => {
                const itemId = charge.inventory_item;
                const itemName = inventoryItems[itemId] || `Item ${itemId}`;
                const quantity = Number(charge.quantity_used) || 0;
                
                // Update type-specific totals
                summary.inventory[type][itemName] = (summary.inventory[type][itemName] || 0) + quantity;
                // Update combined totals
                summary.inventory.combined[itemName] = (summary.inventory.combined[itemName] || 0) + quantity;
              });
            }

    // Process service items
    if (record.service_item_charges) {
        record.service_item_charges.forEach(charge => {
        const itemId = charge.service_item;
        const itemName = serviceItems[itemId] || `Item ${itemId}`;
        const quantity = Number(charge.quantity_used) || 0;
        
        summary.service[type][itemName] = (summary.service[type][itemName] || 0) + quantity;
        summary.service.combined[itemName] = (summary.service.combined[itemName] || 0) + quantity;
        });
    }

            // Process miscellaneous items
            if (record.miscellaneous_item_charges) {
              record.miscellaneous_item_charges.forEach(charge => {
                const chargeInfo = {
                  name: charge.custom_name,
                  quantity: Number(charge.quantity_used) || 0,
                  date: dwr.date,
                  dwr_number: dwr.ticket_number
                };
                
                summary.misc[type].push(chargeInfo);
                summary.misc.combined.push(chargeInfo);
              });
            }
          });
        });

        setInventorySummary(summary.inventory);
        setServiceSummary(summary.service);
        setMiscCharges(summary.misc);
      } catch (error) {
        console.error('Error fetching DWR data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (Object.keys(inventoryItems).length > 0) {
      fetchDWRData();
    }
}, [well, inventoryItems, serviceItems]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSummaryTable = (type) => {
    const inventoryItems = Object.entries(inventorySummary[type]);
    const serviceItems = Object.entries(serviceSummary[type]);
    const miscItems = miscCharges[type];

    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }
  

    return (
      <Box sx={{ mt: 2 }}>
        {/* Inventory Items */}
        {inventoryItems.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>Inventory Items</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="right">Total Quantity Used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryItems.map(([name, quantity]) => (
                  <TableRow key={`inventory-${name}`}>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">{quantity.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {/* Service Items */}
        {serviceItems.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Service Items</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="right">Total Quantity Used</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceItems.map(([name, quantity]) => (
                  <TableRow key={`service-${name}`}>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">{quantity.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {/* Miscellaneous Items */}
        {miscItems.length > 0 && (
          <>
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Miscellaneous Items</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="right">Quantity Used</TableCell>
                  <TableCell align="right">DWR Number</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {miscItems.map((item, index) => (
                  <TableRow key={`misc-${index}`}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">{item.quantity.toFixed(2)}</TableCell>
                    <TableCell align="right">{item.dwr_number}</TableCell>
                    <TableCell align="right">
                      {new Date(item.date).toLocaleDateString("en-US", { timeZone: "UTC"})}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}

        {!loading && 
         inventoryItems.length === 0 && 
         serviceItems.length === 0 && 
         miscItems.length === 0 && (
          <Typography sx={{ mt: 2, textAlign: 'center' }}>
            No items found
          </Typography>
        )}
      </Box>
    );
  };

// Add function to calculate hours between times (HH:MM:SS format)
const calculateDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    return endHours - startHours + (endMinutes - startMinutes) / 60;
  };
  
  // Add function to find longest duration employee record and its rig time
  const getHoursFromTimeRecord = (timeRecord) => {
    if (!timeRecord?.employee_time_records?.length) {
      return { crewHours: 0, rigHours: 0 };
    }
  
    let longestDuration = 0;
    let selectedRecord = null;
  
    timeRecord.employee_time_records.forEach(record => {
      const duration = calculateDuration(record.start_time, record.end_time);
      if (duration > longestDuration) {
        longestDuration = duration;
        selectedRecord = record;
      }
    });
  
    return {
      crewHours: longestDuration,
      rigHours: selectedRecord ? Number(selectedRecord.rig_time) : 0
    };
  };
  
  // Update renderGeneralTable
  const renderGeneralTable = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      );
    }
  
    const sortedDwrs = [...dwrTotals].sort((a, b) => new Date(a.date) - new Date(b.date));
    const earliestDate = sortedDwrs[0]?.date;
    const latestDate = sortedDwrs[sortedDwrs.length - 1]?.date;
    const uniqueDates = new Set(sortedDwrs.map(dwr => dwr.date)).size;
  
    // Calculate totals including hours
    const totals = sortedDwrs.reduce((acc, dwr) => {
      const { crewHours, rigHours } = getHoursFromTimeRecord(dwr.time_record);
      return {
        amount: acc.amount + dwr.offTurnkeyTotal,
        crewHours: acc.crewHours + crewHours,
        rigHours: acc.rigHours + rigHours
      };
    }, { amount: 0, crewHours: 0, rigHours: 0 });
  
    return (
      <Box sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Days</TableCell>
              <TableCell align="right">Crew Hours</TableCell>
              <TableCell align="right">Rig Hours</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDwrs.map((dwr) => {
              const { crewHours, rigHours } = getHoursFromTimeRecord(dwr.time_record);
              return (
                <TableRow key={dwr.id}>
                  <TableCell>{new Date(dwr.date).toLocaleDateString("en-US", { timeZone: "UTC" })}</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">{crewHours.toFixed(2)}</TableCell>
                  <TableCell align="right">{rigHours.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    ${dwr.offTurnkeyTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </TableCell>
                </TableRow>
              );
            })}
            
            {/* Summary row */}
            <TableRow sx={{ 
              '& td': { 
                fontWeight: 'bold',
                borderTop: '2px solid black'
              } 
            }}>
              <TableCell>
                {earliestDate && latestDate 
                  ? `${new Date(earliestDate).toLocaleDateString("en-US", { timeZone: "UTC" })} - ${new Date(latestDate).toLocaleDateString("en-US", { timeZone: "UTC" })}`
                  : '-'}
              </TableCell>
              <TableCell align="right">{uniqueDates || 0}</TableCell>
              <TableCell align="right">{totals.crewHours.toFixed(2)}</TableCell>
              <TableCell align="right">{totals.rigHours.toFixed(2)}</TableCell>
              <TableCell align="right">
                ${totals.amount.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    );
  };

  return (
    <Paper sx={{ p: 2 }}>
        <Tabs 
        value={tabValue} 
        onChange={handleTabChange}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
        <Tab label="General" />
        <Tab label="On Turnkey" />
        <Tab label="Off Turnkey" />
        <Tab label="Combined" />
        </Tabs>


        {tabValue === 0 && renderGeneralTable()}
        {tabValue === 1 && renderSummaryTable('onTurnkey')}
        {tabValue === 2 && renderSummaryTable('offTurnkey')}
        {tabValue === 3 && renderSummaryTable('combined')}
    </Paper>
  );
};

export default WellSummary;
