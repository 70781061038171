import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

const appUrl = process.env.REACT_APP_APP_URL;

const QueryView = () => {
  const [queries, setQueries] = useState([]);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [parameters, setParameters] = useState({});
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQueries();
  }, []);

  const fetchQueries = async () => {
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.get(`${appUrl}/jmrapp/saved-queries/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setQueries(response.data);
    } catch (err) {
      setError('Failed to fetch queries');
    }
  };

  const executeQuery = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('my-jwt');
      const response = await axios.post(
        `${appUrl}/jmrapp/saved-queries/${selectedQuery.id}/execute/`,
        { parameters },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setResults(response.data.results);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to execute query');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = () => {
    if (!selectedQuery) return;
    const token = localStorage.getItem('my-jwt');
    const queryString = new URLSearchParams(parameters).toString();
    window.location.href = 
      `${appUrl}/jmrapp/saved-queries/${selectedQuery.id}/download_csv/?${queryString}`;
  };

  return (
    <Card sx={{ m: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          SQL Query Interface
        </Typography>

        <Select
          fullWidth
          value={selectedQuery?.id || ''}
          onChange={(e) => {
            const query = queries.find(q => q.id === e.target.value);
            setSelectedQuery(query);
            setParameters({});
            setResults(null);
          }}
          sx={{ mb: 2 }}
        >
          <MenuItem value="">Select a query</MenuItem>
          {queries.map(query => (
            <MenuItem key={query.id} value={query.id}>
              {query.name}
            </MenuItem>
          ))}
        </Select>

        {selectedQuery?.parameters && Object.keys(selectedQuery.parameters).length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Parameters
            </Typography>
            {Object.entries(selectedQuery.parameters).map(([key, info]) => (
              <TextField
                key={key}
                label={info.label || key}
                value={parameters[key] || ''}
                onChange={(e) => setParameters({
                  ...parameters,
                  [key]: e.target.value
                })}
                placeholder={info.placeholder}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            ))}
          </Box>
        )}

        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            onClick={executeQuery}
            disabled={!selectedQuery || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Execute Query'}
          </Button>
          {results && (
            <Button
              variant="outlined"
              onClick={downloadCSV}
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </Button>
          )}
        </Box>

        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {results && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(results[0] || {}).map(header => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {results.map((row, i) => (
                  <TableRow key={i}>
                    {Object.values(row).map((value, j) => (
                      <TableCell key={j}>
                        {value?.toString()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default QueryView;
