// DynamicWorkDescription.jsx
import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography } from '@mui/material';

const DynamicWorkDescription = ({ 
  templateText, 
  initialValues = {}, 
  onChange,
  disabled = false 
}) => {
  const [inputValues, setInputValues] = useState(initialValues);
  
  useEffect(() => {
    setInputValues(initialValues);
  }, [templateText, initialValues]);

  const parseTemplate = (template) => {
    const segments = [];
    let currentText = '';
    let inputCount = 0;
    
    for (let i = 0; i < template.length; i++) {
      if (template[i] === '*' && template[i + 1] === '_') {
        if (currentText) {
          segments.push({ type: 'text', content: currentText });
          currentText = '';
        }
        segments.push({ type: 'input', id: inputCount });
        inputCount++;
        i++; // Skip the underscore
      } else {
        currentText += template[i];
      }
    }
    
    if (currentText) {
      segments.push({ type: 'text', content: currentText });
    }
    
    return segments;
  };

  const handleInputChange = (id, value) => {
    const newValues = { ...inputValues, [id]: value };
    setInputValues(newValues);
    onChange?.(newValues);
  };

  const segments = parseTemplate(templateText);

  return (
    <Box 
      className="dynamic-description-container" 
      sx={{ 
        width: '100%', 
        display: 'block', 
        whiteSpace: 'normal', 
        wordBreak: 'break-word',
        overflowWrap: 'break-word'
      }}
    >
      {segments.map((segment, index) => (
        segment.type === 'text' ? (
          <Typography 
            key={index} 
            component="span"
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
          >
            {segment.content}
          </Typography>
        ) : (
          <TextField
            key={index}
            size="small"
            value={inputValues[segment.id] || ''}
            onChange={(e) => handleInputChange(segment.id, e.target.value)}
            disabled={disabled}
            className="custom-text-field"
            multiline
            />
        )
      ))}
    </Box>
  );
};

export default DynamicWorkDescription;
