import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    Box, 
    Paper, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    Alert,
    AlertTitle,
    TablePagination,
    Chip,
    TextField,
    InputAdornment
} from '@mui/material';
import { Lock, LockOpen, Search } from '@mui/icons-material';

const PriceBookInventoryTable = ({ well }) => {
    const [inventoryData, setInventoryData] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({
        itemName: '',
        price: '',
        pricingLevel: ''
    });

    useEffect(() => {
        const fetchInventoryData = async () => {
            if (!well?.id) return;
            
            setIsLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('my-jwt');
                const response = await axios.get(
                    `${process.env.REACT_APP_APP_URL}/jmrapp/api/well-inventory-details/`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                        params: { well_id: well.id }
                    }
                );

                setInventoryData(response.data.inventory_data);
            } catch (err) {
                console.error('Error fetching inventory data:', err);
                setError(err.response?.data?.error || err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchInventoryData();
    }, [well]);

    useEffect(() => {
        const filtered = inventoryData.filter(item => {
            return (
                item.inventory_item.name.toLowerCase().includes(filters.itemName.toLowerCase()) &&
                item.price.toString().includes(filters.price) &&
                (item.pricing_level_id || '').toLowerCase().includes(filters.pricingLevel.toLowerCase())
            );
        });
        setFilteredItems(filtered);
        setPage(0);
    }, [filters, inventoryData]);

    const handleFilterChange = (field) => (event) => {
        setFilters(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Error</AlertTitle>
                <Typography>{error}</Typography>
            </Alert>
        );
    }

    if (!filteredItems.length) {
        return (
            <Alert severity="info" sx={{ mt: 2 }}>
                <AlertTitle>No Data</AlertTitle>
                No inventory pricebook entries found.
            </Alert>
        );
    }

    return (
        <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                Inventory Price Book - {well.customer?.name}
            </Typography>
            
            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <TextField
                    label="Filter by Item Name"
                    variant="outlined"
                    size="small"
                    value={filters.itemName}
                    onChange={handleFilterChange('itemName')}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    label="Filter by Price"
                    variant="outlined"
                    size="small"
                    value={filters.price}
                    onChange={handleFilterChange('price')}
                />
                <TextField
                    label="Filter by Pricing Level"
                    variant="outlined"
                    size="small"
                    value={filters.pricingLevel}
                    onChange={handleFilterChange('pricingLevel')}
                />
            </Box>
            
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item Name</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell>Pricing Level</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Valid From</TableCell>
                            <TableCell>Valid To</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredItems
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.inventory_item.name}</TableCell>
                                    <TableCell align="right">
                                        ${parseFloat(item.price).toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {item.pricing_level_id || 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        <Chip 
                                            icon={item.locked ? <Lock /> : <LockOpen />}
                                            label={item.locked ? "Locked" : "Unlocked"}
                                            color={item.locked ? "error" : "success"}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.valid_from).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        {item.valid_to 
                                            ? new Date(item.valid_to).toLocaleDateString() 
                                            : 'Current'}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredItems.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        </Paper>
    );
};

export default PriceBookInventoryTable;
