import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Box, 
  Checkbox, 
  FormControlLabel, 
  Typography,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import '../styles/WorkDescriptionsModal.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 3,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
};

const WorkDescriptionsModal = ({ 
  open, 
  handleClose, 
  setSelectedWorkDescriptions, 
  workDescriptions,
  disabled,
  existingDescriptions = []  // Added for duplicate checking
}) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState(null); // Track which item caused duplicate

  useEffect(() => {
    setSelectedIds([]);
    setExpanded('');
    setShowDuplicateError(false);
    setDuplicateItem(null);
  }, [open]);

  // Active descriptions sorted alphabetically
  const activeWorkDescriptions = workDescriptions
    .filter(desc => desc.is_active)
    .sort((a, b) => a.description.localeCompare(b.description));

  // // Group descriptions
  // const groupedWorkDescriptions = activeWorkDescriptions.reduce((acc, desc) => {
  //   const group = desc.group || 'Uncategorized';
  //   if (!acc[group]) {
  //     acc[group] = [];
  //   }
  //   acc[group].push(desc);
  //   return acc;
  // }, {});

  // Sort groups alphabetically
const groupedWorkDescriptions = Object.fromEntries(
  Object.entries(
    activeWorkDescriptions.reduce((acc, desc) => {
      const group = desc.group || 'Uncategorized';
      if (!acc[group]) acc[group] = [];
      acc[group].push(desc);
      return acc;
    }, {})
  ).sort(([a], [b]) => a.localeCompare(b))
);

  const isDuplicate = (id) => {
    return existingDescriptions.some(desc => desc.id === id || desc.workDescriptionId === id);
  };

  const handleAccordionChange = (group) => (event, isExpanded) => {
    setExpanded(isExpanded ? group : '');
  };

  const handleSelect = (id, description) => {
    // if (isDuplicate(id)) {
    //   setShowDuplicateError(true);
    //   setDuplicateItem(description);
    //   return;
    // }

    setSelectedIds(prev => {
      const currentIndex = prev.indexOf(id);
      if (currentIndex === -1) {
        return [...prev, id];
      }
      return prev.filter(itemId => itemId !== id);
    });
    // setShowDuplicateError(false);
    // setDuplicateItem(null);
  };

  const handleSubmit = () => {
    // Get full description objects for selected IDs
    const selectedDescriptions = activeWorkDescriptions.filter(desc => 
      selectedIds.includes(desc.id)
    );
    
    // Additional validation before closing
    if (selectedDescriptions.length === 0) {
      return; // Don't close if nothing selected
    }

    // // Check for any last-minute duplicates
    // const hasDuplicates = selectedDescriptions.some(desc => isDuplicate(desc.id));
    // if (hasDuplicates) {
    //   setShowDuplicateError(true);
    //   return;
    // }

    setSelectedWorkDescriptions(selectedDescriptions);
    handleClose();
  };

  const renderDescriptionPreview = (description) => {
    const hasTemplate = !!description.template_text;
    const inputCount = hasTemplate ? (description.template_text.match(/\*_/g) || []).length : 0;

    return (
      <Box>
        <Typography variant="body2">
          {description.description}
          {duplicateItem?.id === description.id && (
            <Typography component="span" color="error" sx={{ ml: 1, fontSize: '0.8rem' }}>
              (Already added)
            </Typography>
          )}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="work-descriptions-modal-title"
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Select Work Descriptions
        </Typography>

        <Box sx={{ flex: 1, overflow: 'auto', mb: 2 }}>
          {Object.entries(groupedWorkDescriptions).map(([group, descriptions]) => (
            <Accordion
              key={group}
              expanded={expanded === group}
              onChange={handleAccordionChange(group)}
            >
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{ bgcolor: 'background.default' }}
              >
                <Typography sx={{ fontWeight: 'medium' }}>
                  {group} ({descriptions.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {descriptions.map((desc) => (
                    <Paper
                      key={desc.id}
                      elevation={0}
                      sx={{
                        p: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                        '&:hover': { bgcolor: 'action.hover' }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedIds.includes(desc.id)}
                              onChange={() => handleSelect(desc.id, desc)}
                              disabled={disabled}
                            />
                          }
                          label={renderDescriptionPreview(desc)}
                          sx={{ flex: 1, mr: 2, ml: 2 }} // Add margins
                          />
                        {/* {desc.template_text && (
                          <Tooltip title="This description includes fillable fields">
                            <IconButton size="small">
                              <InfoIcon fontSize="small" color="info" />
                            </IconButton>
                          </Tooltip>
                        )} */}
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        {/* {showDuplicateError && (
          <Typography color="error" sx={{ mb: 2 }}>
            Cannot add duplicate work descriptions
          </Typography>
        )} */}

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          gap: 2,
          borderTop: 1,
          borderColor: 'divider',
          pt: 2
        }}>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={selectedIds.length === 0 || disabled}
          >
            Add Selected ({selectedIds.length})
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default WorkDescriptionsModal;
