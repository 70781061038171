
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}
//console.log('APP URL: ', appUrl)

// Configure axios defaults for CSRF
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// Add this global interceptor
axios.interceptors.request.use(config => {
    const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
        
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    
    // Log the request headers for debugging
    console.log('Request URL:', config.url);
    console.log('Request headers:', config.headers);
    
    return config;
}, error => {
    return Promise.reject(error);
});


interface User {
    id: number;
    email: string;
    is_staff: boolean;
    is_manager: boolean;
    is_superuser: boolean;
    assigned_rig: number | null;
    managed_rigs: number[]; // Array of rig IDs managed by the user
    permissions: string[]; // Array of user permissions
    is_district_manager: boolean;
}

interface AuthProps {
    authState?: { token: string | null; authenticated: boolean | null; user?: User };
    onLogin?: (email: string, password: string) => Promise<any>;
    onLogout?: () => Promise<any>;
    hasPermission?: (permission: string) => boolean;
}

export const TOKEN_KEY = 'my-jwt';
export const RESTLET_TOKEN_KEY = 'restlet-token';
export const USER_DATA_KEY = 'user-data'; // Key for storing user data in localStorage
export const REFRESH_TOKEN_KEY = 'my-refresh-token'; // Key for refresh token
export const API_URL = appUrl + `/jmrapp`;
export const CACHED_WELLS = 'cachedWells';

const AuthContext = createContext<AuthProps>({});

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
    const [authState, setAuthState] = useState<{
        token: string | null;
        refreshToken: string | null;
        authenticated: boolean | null;
        user?: User;
    }>({
        token: null,
        refreshToken: null,
        authenticated: false,
        user: undefined,
    });

    // Load the auth state (token, refreshToken, user) from localStorage when the component mounts
    useEffect(() => {
        const storedToken = localStorage.getItem(TOKEN_KEY);
        const storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        const storedUserData = localStorage.getItem(USER_DATA_KEY);

        const user = storedUserData ? JSON.parse(storedUserData) : undefined;

        if (storedToken && storedRefreshToken && user) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
            axios.defaults.withCredentials = true;
            setAuthState({
                token: storedToken,
                refreshToken: storedRefreshToken,
                authenticated: true,
                user: user,
            });
        }
    }, []);

    let refreshTimeoutId: number | NodeJS.Timeout | null | undefined = null;

    const calculateExpiresAt = (expiresIn: any) => {
        return new Date().getTime() + expiresIn * 1000; // expiresIn is assumed to be in seconds
    };

    const DEFAULT_EXPIRES_IN = 18 * 60 * 60; // 18 hours in seconds; // Default expiration time in seconds (e.g., 30 minutes)

    const setRefreshTimeout = (expiresIn: any, logout: any) => {
        // Clear any existing timeout to ensure we don't stack multiple calls
        if (refreshTimeoutId) {
            clearTimeout(refreshTimeoutId);
        }

        const timeoutDuration = DEFAULT_EXPIRES_IN * 1000; // Timeout duration in ms
        //console.log(`Setting token expiration timeout for ${timeoutDuration}ms`);

        refreshTimeoutId = setTimeout(() => {
            console.log("Access token expired. Logging out and redirecting to login page.");
            logout();
            window.location.href = '/login'; // Redirect to the login page
        }, timeoutDuration);
    };

    // Login function updated to save user data in localStorage
    const login = async (email: string, password: string) => {
        try {

        const csrfResponse = await axios.get(`${API_URL}/get-csrf-token/`, {
            withCredentials: true
        });
        
        // Use the token from the response
        const csrfToken = csrfResponse.data.csrfToken;
        console.log('CSRF Token from response:', csrfToken);

        const headers = {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
        };
        console.log('Request Headers:', headers);

        const response = await axios.post(`${API_URL}/api/token/`, {
            email: email.toLowerCase(),
            password: password,
        }, {
            headers: headers,
            withCredentials: true
        });

        const { access, refresh, user, expires_in } = response.data;


            // Save token, refreshToken, and user data to localStorage
            localStorage.setItem(TOKEN_KEY, access);
            localStorage.setItem(REFRESH_TOKEN_KEY, refresh);
            localStorage.setItem(USER_DATA_KEY, JSON.stringify(user)); // Convert user object to string before storing

            axios.defaults.headers.common['Authorization'] = `Bearer ${access}`;
            axios.defaults.withCredentials = true;
            
            setAuthState((prevState) => ({
                ...prevState,
                token: localStorage.getItem(TOKEN_KEY),
                refreshToken: refresh,
                authenticated: true,
                user: user,
            }));



            // Fetch and store the restlet token
            const restletTokenResponse = await axios.get(`${API_URL}/api/get-restlet-token/`, {
                headers: {
                    'Authorization': `Bearer ${access}`
                }
            });
            const restletToken = restletTokenResponse.data.token;
            localStorage.setItem(RESTLET_TOKEN_KEY, restletToken);

            setRefreshTimeout(expires_in, logout);

            return response;
        } catch (e) {
            return { error: true, msg: (e as any).response.data.msg };
        }
    };

    // Function to clear the cache of wells
    const clearWellsCache = () => {
        localStorage.removeItem(CACHED_WELLS);
    };

    // Logout function updated to remove user data from localStorage
    const logout = async () => {
        // Remove token, refreshToken, and user data from localStorage
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        localStorage.removeItem(USER_DATA_KEY);
        localStorage.removeItem(RESTLET_TOKEN_KEY);

        // Clear Wells Cache
        clearWellsCache();

        axios.defaults.headers.common['Authorization'] = '';
        axios.defaults.withCredentials = true;

        setAuthState({
            token: null,
            refreshToken: null,
            authenticated: false,
            user: undefined,
        });
    };

    // Function to check if the user has a specific permission
    const hasPermission = (permission: string) => {
        return authState.user?.permissions.includes(permission) || false;
    };

    const value = {
        onLogin: login,
        onLogout: logout,
        authState,
        isAuthenticated: authState.authenticated, // Expose isAuthenticated State
        isSuperuser: authState.user?.is_superuser,
        hasPermission, // Expose the hasPermission function
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
