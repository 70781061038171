import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  TextField 
} from '@mui/material';
import WorkDescriptionsModal from './WorkDescriptionsModal';
import DynamicWorkDescription from './DynamicWorkDescription';
import '../styles/WorkAssignment.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const WorkAssignment = ({
  workAssignments, 
  setWorkAssignments, 
  workDescriptions, 
  onDeleteAssignment, 
  disabled
}) => {
  // EXISTING STATE
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false);

  // EXISTING MODAL HANDLERS
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowDuplicateError(false);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  // MODIFIED handleWorkDescriptionSelect TO HANDLE TEMPLATES
  const handleWorkDescriptionSelect = (selectedIds) => {
    if (!Array.isArray(workDescriptions) || workDescriptions.length === 0) {
      console.error("workDescriptions is undefined, not an array, or empty");
      return;
    }

    let isDuplicate = false;
    const updatedAssignments = [...workAssignments];

    selectedIds.forEach(id => {
      if (!updatedAssignments.some(assignment => 
        assignment.workDescriptionId === id || assignment.work_description?.id === id
      )) {
        const descriptionObj = workDescriptions.find(desc => desc.id === id);
        if (descriptionObj) {
          updatedAssignments.push({
            work_description: descriptionObj,
            workDescriptionId: descriptionObj.id,
            description: descriptionObj.description,
            fromTime: '06:00',
            toTime: '06:30',
            input_values: {},
            is_legacy: !descriptionObj.template_text
          });
        } else {
          console.error(`Description with id ${id} not found.`);
        }
      } else {
        isDuplicate = true;
      }
    });

    if (isDuplicate) {
      setShowDuplicateError(true);
    } else {
      setShowDuplicateError(false);
      setWorkAssignments(updatedAssignments);
      handleCloseModal();
    }
  };

  // EXISTING TIME HANDLING
  const handleTimeChange = (index, field, value) => {
    const selectedTime = new Date(`1970-01-01T${value}Z`);
    let hours = selectedTime.getUTCHours();
    let minutes = selectedTime.getUTCMinutes();
  
    // Round to nearest 15 minutes
    if (minutes < 8) {
      minutes = 0;
    } else if (minutes < 23) {
      minutes = 15;
    } else if (minutes < 38) {
      minutes = 30;
    } else if (minutes < 53) {
      minutes = 45;
    } else {
      minutes = 0;
      hours = (hours + 1) % 24;
    }
  
    const newTimeValue = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  
    const updatedAssignments = [...workAssignments];
    updatedAssignments[index][field] = newTimeValue;
    setWorkAssignments(updatedAssignments);
  };

  // EXISTING SUBMIT HANDLER
  const handleSubmit = async (event) => {
    event.preventDefault();
  };

  // MODIFIED TO HANDLE LEGACY DESCRIPTIONS ONLY
  const handleWorkDescriptionChange = (index, value) => {
    const updatedAssignments = [...workAssignments];
    if (updatedAssignments[index].is_legacy) {
      updatedAssignments[index].description = value;
      setWorkAssignments(updatedAssignments);
    }
  };

  // NEW METHOD FOR TEMPLATE INPUTS
  const handleInputValuesChange = (index, values) => {
    const updatedAssignments = [...workAssignments];
    updatedAssignments[index].input_values = values;
    setWorkAssignments(updatedAssignments);
  };

  // EXISTING EFFECT FOR SAVING
  useEffect(() => {
    const saveAssignmentsIfNeeded = async () => {
      const readyToSaveAssignments = workAssignments.filter(assignment => 
        assignment.fromTime && assignment.toTime
      );

      if (readyToSaveAssignments.length > 0) {
        // Implement save logic here
      }
    };

    saveAssignmentsIfNeeded();
  }, [workAssignments]);

  // EXISTING DELETE HANDLER
  const handleDeleteClick = (assignmentId, index) => {
    if (assignmentId) {
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this work assignment?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => onDeleteAssignment(assignmentId),
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      setWorkAssignments(prevAssignments => 
        prevAssignments.filter((_, i) => i !== index)
      );
    }
  };

  return (
    <div className="w-full overflow-x-auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="75%" className="work-description-cell">Work Description</TableCell>
            <TableCell width="10%" className="from-time-cell">From Time</TableCell>
            <TableCell width="10%" className="to-time-cell">To Time</TableCell>
            <TableCell width="5%" className="delete-cell">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workAssignments.map((assignment, index) => (
            <TableRow key={index}>
              <TableCell className="work-description-cell">
                {assignment.is_legacy ? (
                  <TextField
                    value={assignment.description}
                    onChange={(e) => handleWorkDescriptionChange(index, e.target.value)}
                    multiline
                    rows={4}
                    disabled={disabled}
                    className="custom-text-field"
                  />

                ) : (
                  <div className="w-full">
                    <DynamicWorkDescription
                      templateText={assignment.work_description.template_text}
                      initialValues={assignment.input_values}
                      onChange={(values) => handleInputValuesChange(index, values)}
                      disabled={disabled}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell className="from-time-cell">
                <TextField
                  type="time"
                  inputProps={{ step: "900" }}
                  value={assignment.fromTime}
                  onChange={(e) => handleTimeChange(index, 'fromTime', e.target.value)}
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell className="to-time-cell">
                <TextField
                  type="time"
                  inputProps={{ step: "900" }}
                  value={assignment.toTime}
                  onChange={(e) => handleTimeChange(index, 'toTime', e.target.value)}
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell className="delete-cell">
                <Button 
                  onClick={() => handleDeleteClick(assignment.id, index)}
                  disabled={disabled}
                  color="error"
                  variant="outlined"
                  size="small"
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default WorkAssignment;
