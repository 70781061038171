import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    Box, 
    Paper, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    Alert,
    AlertTitle,
    TablePagination,
    TextField,
    InputAdornment
} from '@mui/material';
import { Search } from '@mui/icons-material';

const PriceBookServicesTable = ({ well }) => {
    const [services, setServices] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({
        serviceName: '',
    });

    useEffect(() => {
        const fetchServices = async () => {
            if (!well?.customer?.id) {
                setError('No customer ID available');
                return;
            }
            
            setIsLoading(true);
            setError(null);

            try {
                const token = localStorage.getItem('my-jwt');
                const response = await axios.get(
                    `${process.env.REACT_APP_APP_URL}/jmrapp/pricebooks/`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                        params: { customer_id: well.customer.id }
                    }
                );
                
                console.log('Service data:', response.data);
                const data = response.data || [];
                setServices(data);
                setFilteredServices(data);
            } catch (err) {
                console.error('Fetch error:', err);
                setError(err.response?.data?.error || err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchServices();
    }, [well]);

    useEffect(() => {
        const filtered = services.filter(service => 
            service.name.toLowerCase().includes(filters.serviceName.toLowerCase())
        );
        setFilteredServices(filtered);
        setPage(0);
    }, [filters, services]);

    const handleFilterChange = (event) => {
        setFilters({ serviceName: event.target.value });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                <AlertTitle>Error</AlertTitle>
                <Typography>{error}</Typography>
            </Alert>
        );
    }

    if (!filteredServices.length) {
        return (
            <Alert severity="info" sx={{ mt: 2 }}>
                <AlertTitle>No Data</AlertTitle>
                No service items found for this customer.
            </Alert>
        );
    }

    return (
        <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
            <Typography variant="h6" component="h2" gutterBottom>
                Service Price Book for {well.customer?.name || 'Unknown Customer'}
            </Typography>
            
            <Box sx={{ mb: 2 }}>
                <TextField
                    label="Filter by Service Name"
                    variant="outlined"
                    size="small"
                    value={filters.serviceName}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Service Name</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredServices
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((service) => (
                                <TableRow key={service.id}>
                                    <TableCell>{service.name}</TableCell>
                                    <TableCell align='left'>{service.unit_type || 'N/A'}</TableCell>
                                    <TableCell align="left">
                ${parseFloat(service.price || 0).toFixed(2)}
            </TableCell>                                    <TableCell align='left'>
                                        {service.is_active ? 'Active' : 'Inactive'}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={filteredServices.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        </Paper>
    );
};

export default PriceBookServicesTable;
