import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/WellRows.css';

const WellRows = ({ data, onSelectWell }) => {
  console.log("WELLS: ", data);

  const [filterModel, setFilterModel] = useState(() => {
    const savedFilter = localStorage.getItem('wellRowsFilter');
    return savedFilter ? JSON.parse(savedFilter) : { items: [] };
  });

  useEffect(() => {
    localStorage.setItem('wellRowsFilter', JSON.stringify(filterModel));
  }, [filterModel]);

  const formatArea = (areaValue) => {
    return areaValue === 'Deleware Basin' ? 'Delaware Basin' : areaValue;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'area', headerName: 'AREA', flex: 1, valueGetter: (params) => formatArea(params.value)},
    { field: 'assigned_rig', headerName: 'RIG', flex: 1, valueGetter: (params) => params.row.assigned_rig || '' },
    { field: 'customer_name', headerName: 'CUSTOMER', flex: 1 },
    { field: 'api', headerName: 'API', flex: 1 },
    { field: 'dwrDate', headerName: 'DWR DATE', flex: 1 },
    { field: 'status', headerName: 'STATUS', flex: 1 },
    { field: 'afe_po', headerName: 'AFE/PO', flex: 1 },
  ];

  const rows = data.map((well, index) => {
    return {
      id: well.id || index,
      name: well.name,
      area: well.area || '',
      county: well.county || '',
      state: well.state || '',
      assigned_rig: well.assigned_rig || 'No Rig',
      customer_name: well.customer_name || 'No Customer',
      customer_id: well.customer_id || 'No Customer',
      customer_address: well.customer_address || 'No Address',
      lease_description: well.lease_description || '',
      latitude: well.latitude || '',
      longitude: well.longitude || '',
      api: well.api || '',
      afe_po: well.afe_po || '',
      dwrDate: well.dwrDate || '',
      status: well.status || '',
      job_number: well.job_number || '',
      customer_representative: well.customer_representative || '',
      customer_nsid: well.customer_ns_iid || '',
      netsuite_id: well.netsuite_id || '',
    };
  });


  rows.sort((a, b) => {
    if (a.dwrDate && b.dwrDate) {
      return new Date(b.dwrDate) - new Date(a.dwrDate);
    } else if (a.dwrDate) {
      return -1;
    } else if (b.dwrDate) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div style={{ height: '75vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
        onRowClick={(params) => onSelectWell(params.row)}
      />
    </div>
  );
};

export default WellRows;
