import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import PriceBookInventoryTable from './PriceBookInventoryTable';
import PriceBookServicesTable from './PriceBookServicesTable';

const PriceBookView = ({ well }) => {
    const [activeTab, setActiveTab] = useState('inventory');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange}
                    aria-label="pricebook tabs"
                >
                    <Tab label="Inventory" value="inventory" />
                    <Tab label="Services" value="services" />
                </Tabs>
            </Box>

            {activeTab === 'inventory' && (
                <PriceBookInventoryTable well={well} />
            )}
            {activeTab === 'services' && (
                <PriceBookServicesTable well={well} />
            )}
        </Box>
    );
};

export default PriceBookView;